import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMessageService } from 'src/app/app-service/app-message.service';
import { ZzMenuTreesVM } from 'src/app/app-service/app-user-menu.service';
import { UserMainMenuHttpService } from 'src/app/app-service/user-main-menu-http.service';
import { ZzMenu } from 'src/app/zz/zz-user/shared/zz-user.model';
import { AppMenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-menu-dialog',
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.css']
})
export class MenuDialogComponent {
  @ViewChild(AppMenuComponent, { static: true })
  menuComp$!: AppMenuComponent;
  constructor(
    public msgService: AppMessageService,
    public dialogRef: MatDialogRef<MenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: UserMainMenuHttpService
  ) {
    this.getMenus();
  }
  private getMenus() {
    this.msgService.startProcessing();
    this.httpService.getUserMenu().subscribe({
      next: (treeItem: ZzMenuTreesVM | null) => {
        if (treeItem && treeItem.msgItem?.isOK) {
          this.menuComp$.setItems(treeItem.mtrItems!)
          //Set Menu here
        }
        else {
          this.menuComp$.setItems([])

        }
      },
      error: (error) => {
        this.menuComp$.setItems([])
        this.msgService.add(error);
      },
      complete: () => {
        this.msgService.stopProcessing();
      },
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onOkClick(menu: ZzMenu): void{
    this.dialogRef.close(menu);
  }
}
