<div *ngIf="data.type==='error'" class="error">
  <button id="x" (click)="close()">X
  </button>
  <h3>Thông báo lỗi</h3>
  <hr />
  <li *ngFor="let message of data.messages; index as i">
    {{message}}
  </li>
</div>
<div *ngIf="data.type==='information'" class="success">
  <button id="x" (click)="close()">X
  </button>
  <h3>Thông báo</h3>
  <hr />
  <li *ngFor="let message of data.messages; index as i">
    {{message}}
  </li>
</div>
<div *ngIf="data.type==='warning'" class="warning">
  <button id="x" (click)="close()">X
  </button>
  <h3>Cảnh báo</h3>
  <hr />
  <li *ngFor="let message of data.messages; index as i">
    {{message}}
  </li>
</div>

