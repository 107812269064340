import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { AppNaviBarModule } from './nav-bar/nav-bar.module';
import { httpInterceptorProviders } from './app-service/http-interceptors';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DynamicFieldPipe } from './app-shared/pipe/dynamic-field.pipe';
import { DynamicPipe } from './app-shared/pipe/dynamic-pipe.pipe';
import { MatSpinnerOverlayComponent } from './app-com/mat-spinner-overlay/mat-spinner-overlay.component';
import { AppSnackBarMessageComponent } from './app-com/snack-bar-message-component/snack-bar-message.component';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    MatSpinnerOverlayComponent,
    AppSnackBarMessageComponent,
  ],
  imports: [

    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatIconModule,
    AppRoutingModule,
    AppNaviBarModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),

  ],
  providers: [httpInterceptorProviders,
    [{ provide: DEFAULT_TIMEOUT, useValue: 360000 }],
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    DynamicFieldPipe, DynamicPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
