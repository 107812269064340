import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Auth, User, authState, signOut } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { EMPTY, Observable, Subscription, map } from 'rxjs';
import { AppAccountSerivce } from '../app-service/auth/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  private readonly userDisposable: Subscription|undefined;
  public readonly user: Observable<User | null> = EMPTY;

  showLoginButton = false;
  showLogoutButton = false;

  constructor(
    public readonly accountService: AppAccountSerivce,
    @Optional() private auth: Auth,
    private router: Router

  ) {
    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        if (isLoggedIn && this.accountService.getUserLogin()){
          this.showLoginButton = false;
          this.showLogoutButton = true;
        }
        else{
          this.showLoginButton = true;
          this.showLogoutButton = false;
          this.router.navigate(['/login']);
        }
      });
    }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  async logout() {
    return await signOut(this.auth);
  }

}

