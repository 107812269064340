import { Component, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CSV_ICON, EXCEL_ICON, INVOICE_ICON, PDF_ICON, SO_ICON, WB_ICON } from './app-shared/app-svg-constant';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hệ thống quản lý công ty Bao bì';
  mySubscription: any;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('wb-icon', sanitizer.bypassSecurityTrustHtml(WB_ICON));
    iconRegistry.addSvgIconLiteral('excel-icon', sanitizer.bypassSecurityTrustHtml(EXCEL_ICON));
    iconRegistry.addSvgIconLiteral('pdf-icon', sanitizer.bypassSecurityTrustHtml(PDF_ICON));
    iconRegistry.addSvgIconLiteral('csv-icon', sanitizer.bypassSecurityTrustHtml(CSV_ICON));
    iconRegistry.addSvgIconLiteral('invoice-icon', sanitizer.bypassSecurityTrustHtml(INVOICE_ICON));
    iconRegistry.addSvgIconLiteral('so-icon', sanitizer.bypassSecurityTrustHtml(SO_ICON));
  }

}
