import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ZzMenu, ZzMenuGroup, ZzUser } from '../zz/zz-user/shared/zz-user.model';
import { AppMessage } from '../app-shared/model/app-message';
//Service
@Injectable({ providedIn: 'root' })
export class AppUserMenuService {
  treeItem!: ZzMenuTreesVM;
  menuTitle: string = 'Home';
  username: string = 'Guest';
  constructor() { }
  setTreeItem(treeItem?: ZzMenuTreesVM, username?: string) {
    this.treeItem = treeItem ? { ...treeItem } : {};
    this.username = username || '';
  }
  getTreeItem() {
    return of(this.treeItem);
  }
  getUsername() {
    return of(this.username);
  }
  getMenuTitle() {
    return of(this.menuTitle);
  }
  setMenuTitle(mnuId: string) {
    if (this.treeItem && this.treeItem.mtrItems){
      this.treeItem.mtrItems?.forEach(item => {
        var mnuItem = item.childMenus.find(i => i.mnuId === mnuId);
        if (mnuItem) {
          this.menuTitle = mnuItem.mnuName || '';
          return;
        }
      })
    }
    else{
      this.menuTitle = '';
    }
  }
}

export interface ZzMenuTree {
  //mnuItem: ZzMenu;
  mgrItem?: ZzMenuGroup;
  childMenus: ZzMenu[];
}
export interface ZzMenuTreesVM {
  mtrItems?: ZzMenuTree[];
  user?: ZzUser;
  msgItem?: AppMessage;
}
