
<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item disabled style="color:green;">
    <mat-icon style="color:green;">email</mat-icon>
    <span>
      {{username}}
    </span>
  </button>
  <button mat-menu-item (click)="signOut()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Sign Out</span>
  </button>
</mat-menu>
