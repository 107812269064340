import { Injectable } from "@angular/core";

@Injectable({providedIn:'root'})
export class AppAccountSerivce{
  private _isLoggedIn = false;
  //public username = 'Guest';
  public menuTitle = 'Trang chủ';
  public setUserLogin(username: string){
    this._isLoggedIn = true;
    //this.username = username;
    localStorage.setItem('username',username);
  }
  public setUserLogout(){
    this._isLoggedIn = false;
    localStorage.removeItem('username');
  }
  isLoggedIn(){
    return this._isLoggedIn;
  }
  getUserLogin(){
    return localStorage.getItem('username');

  }
}
