<div style="overflow: scroll; width: 100vw" >
  <mat-expansion-panel *ngFor="let parent of items$; index as i;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{parent.mgrItem?.mgrName}}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="grid-container">
      <ng-template ngFor let-mnuItem [ngForOf]="parent.childMenus" let-i2="index">
        <div class="grid-item">
          <button mat-button color="primary" (click)="buttonClick(mnuItem)">{{mnuItem.mnuName}}</button>
        </div>
      </ng-template>
    </div>
  </mat-expansion-panel>
</div>
