import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ZzMenuTreesVM } from './app-user-menu.service';
import { HandleError, HttpErrorHandler } from './auth/http-error-handler.service';

@Injectable({ providedIn: 'root'})
export class UserMainMenuHttpService {
  private apiUrl: string = environment.apiUrl + 'AppUserMenu';
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AppUserMenuHttpService');
  }
  getUserMenu(): Observable<ZzMenuTreesVM | null> {
    return this.http.get<ZzMenuTreesVM>(this.apiUrl)
      .pipe(
        catchError(this.handleError('getHeroes', null))
      );
  }
}
