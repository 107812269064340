import { Pipe, PipeTransform } from "@angular/core";
import { first } from "rxjs";
@Pipe({
  name: "dynamicField"
})
export class DynamicFieldPipe implements PipeTransform {
  /**
   *
   */
  constructor() { }
  transform(value: any, ...args: any[]): any {
    if (!value || !args || args.length===0){
      return value;
    }

    const column: any = args[0];
    let result = value;
    if (column.field){
      var fields = column.field.split(".");
      if (fields.length > 1){
        let firstField = column.field.split(".")[0];
        if (result[firstField]===null){
          return "";
        }
      }
      column.field.split(".").forEach((f: string) => (result = result[f]));

    }
    return result;
  }
}
