import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppNavBarComponent } from './bar/nav-bar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';

import { AppAccountComponent } from './account/account.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MenuDialogComponent } from './menu-dialog/menu-dialog.component';
import { AppMenuComponent } from './menu/menu.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppNavBarComponent,
    AppAccountComponent,
    MenuDialogComponent,
    AppMenuComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    OverlayModule,
    MatDialogModule
  ],
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
  exports: [
    AppNavBarComponent
  ]
})
export class AppNaviBarModule { }
