import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";


@Component({
  selector: 'app-snack-bar-message',
  templateUrl: 'snack-bar-message.component.html',
  styleUrls: ['snack-bar-message.component.css'],
})
export class AppSnackBarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMessage,
  @Inject(MatSnackBarRef) public snackBarRef: any) {

    snackBarRef.afterDismissed().subscribe(() => {

      this.data = {};
    });
    snackBarRef.onAction().subscribe(() => {

    });
  }
  close(){
    //this.data = [];
    this.snackBarRef.dismiss();
    //this.data = [];
  }
}
export interface SnackBarMessage{
  type?: string; //error, success, warning
  messages?: string[];
}


