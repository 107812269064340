import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent, outlet: 'primary', pathMatch: 'prefix' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'zz-user', loadChildren: () => import('./zz/zz-user/zz-user.module').then(m => m.ZzUserModule) },
  { path: 'zz-group', loadChildren: () => import('./zz/group/zz-group.module').then(m => m.ZzGroupModule) },
  { path: 'zz-dep', loadChildren: () => import('./zz/dep/zz-dep.module').then(m => m.ZzDepModule) },
  { path: 'zz-ape', loadChildren: () => import('./zz/ape/zz-ape.module').then(m => m.ZzApeModule) },
  { path: 'zz-email-smtp', loadChildren: () => import('./zz/email-smtp/zz-email-smtp.module').then(m => m.ZzEmailSmtpModule) },
  { path: 'zz-config', loadChildren: () => import('./zz/zz-config/zz-config.module').then(m => m.ZzConfigModule) },
  { path: 'zz-table', loadChildren: () => import('./zz/table/zz-table.module').then(m => m.ZzTableModule) },
  { path: 'zz-deleted-log', loadChildren: () => import('./zz/deleted-log/zz-deleted-log.module').then(m => m.ZzDeletedLogModule) },
  { path: 'zz-vpo', loadChildren: () => import('./zz-vpo/zz-vpo.module').then(m => m.ZzVatPolicyModule) },

  { path: 'li-paf', loadChildren: () => import('./li-pro/paf/li-pro-area-formula.module').then(m => m.LiProAreaFormulaModule) },
  { path: 'li-uom', loadChildren: () => import('./li-pro/uom/li-unit-of-m.module').then(m => m.LiUnitOfMModule) },
  { path: 'li-tax', loadChildren: () => import('./li-pro/tax/li-tax.module').then(m => m.LiTaxModule) },

  { path: 'li-pac', loadChildren: () => import('./li-pro/pac/li-package.module').then(m => m.LiPackageModule) },
  { path: 'li-pco', loadChildren: () => import('./li-pro/pco/li-pro-color.module').then(m => m.LiProColorModule) },
  { path: 'li-psa', loadChildren: () => import('./li-pro/psa/li-print-sample.module').then(m => m.LiPrintSampleModule) },
  { path: 'li-pca', loadChildren: () => import('./li-pro/pca/li-pro-category.module').then(m => m.LiProCategoryModule) },
  { path: 'li-pmo', loadChildren: () => import('./li-pro/pmo/li-pro-mold.module').then(m => m.LiProMoldModule) },

  { path: 'li-pba', loadChildren: () => import('./li-pro/pba/li-pattern-base.module').then(m => m.LiPatternBaseModule) },
  { path: 'li-plg', loadChildren: () => import('./li-pro/plg/li-pro-layer-group.module').then(m => m.LiProLayerGroupModule) },
  { path: 'li-flute', loadChildren: () => import('./li-pro/flu/li-flute.module').then(m => m.LiFluteModule) },
  { path: 'li-pla', loadChildren: () => import('./li-pro/pla/li-pro-layer.module').then(m => m.LiProLayerModule) },
  { path: 'li-pgr', loadChildren: () => import('./li-pro/pgr/li-pro-group.module').then(m => m.LiProGroupModule) },
  { path: 'li-pro-fg', loadChildren: () => import('./li-pro/pro-fg/li-product-fg.module').then(m => m.LiProductFgModule) },
  { path: 'li-pro-pp', loadChildren: () => import('./li-pro/pro-paper/li-product-paper.module').then(m => m.LiProductPaperModule) },
  { path: 'li-pro-pr', loadChildren: () => import('./li-pro/pro-pr/li-product-pr.module').then(m => m.LiProductPrModule) },
  { path: 'li-pro-sv', loadChildren: () => import('./li-pro/pro-sv/li-product-sv.module').then(m => m.LiProductSvModule) },

  { path: 'li-sup', loadChildren: () => import('./li-par/supplier/li-supplier.module').then(m => m.LiSupplierModule) },
  { path: 'li-cus', loadChildren: () => import('./li-par/customer/li-customer.module').then(m => m.LiCustomerModule) },
  { path: 'li-sub-cus', loadChildren: () => import('./li-par/pad/li-par-address.module').then(m => m.LiParAddressModule) },
  { path: 'li-spe', loadChildren: () => import('./li/spe/li-sale-person.module').then(m => m.LiSalePersonModule) },
  { path: 'li-sar', loadChildren: () => import('./li/sar/li-shipping-area.module').then(m => m.LiShippingAreaModule) },
  { path: 'li-sro', loadChildren: () => import('./li/sro/li-shipping-route.module').then(m => m.LiShippingRouteModule) },
  { path: 'li-spo', loadChildren: () => import('./li/spo/li-shipping-point.module').then(m => m.LiShippingPointModule) },
  { path: 'li-cas', loadChildren: () => import('./li/cas/li-cash.module').then(m => m.LiCashModule) },
  { path: 'li-machine', loadChildren: () => import('./li/machine/li-machine.module').then(m => m.LiMachineModule) },
  { path: 'li-veh', loadChildren: () => import('./li/vehicle/li-vehicle.module').then(m => m.LiVehicleModule) },
  { path: 'li-dri', loadChildren: () => import('./li/driver/li-driver.module').then(m => m.LiDriverModule) },
  { path: 'li-bunch', loadChildren: () => import('./li/bunch/li-bunch.module').then(m => m.LiBunchModule) },
  { path: 'li-store', loadChildren: () => import('./li/sto/li-store.module').then(m => m.LiStoreModule) },

  { path: 'sd-spp', loadChildren: () => import('./sd-spp/sd-spp.module').then(m => m.SdSppModule) },
  { path: 'sd-spm', loadChildren: () => import('./sd-spm/sd-spm.module').then(m => m.SdSpmModule) },
  { path: 'sd-spq', loadChildren: () => import('./sd-spq/sd-spq.module').then(m => m.SdSpqModule) },
  { path: 'sd-spt', loadChildren: () => import('./sd-spt/sd-spt.module').then(m => m.SdSptModule) },
  { path: 'sd-spr', loadChildren: () => import('./sd-spr/sd-spr.module').then(m => m.SdSprModule) },
  { path: 'sd-sor', loadChildren: () => import('./sd-sor/sd-sor.module').then(m => m.SdSorModule) },
  { path: 'sd-sor-line-rpt', loadChildren: () => import('./sd-sor-line-rpt/sd-sor-line-rpt.module').then(m => m.SdSorLineRptModule) },
  { path: 'sd-dor', loadChildren: () => import('./sd-dor/sd-dor.module').then(m => m.SdDorModule) },
  { path: 'sd-dor-line-rpt', loadChildren: () => import('./sd-dor-line-rpt/sd-dor-line-rpt.module').then(m => m.SdDorLineRptModule) },
  { path: 'sd-sin', loadChildren: () => import('./sd-sin/sd-sin.module').then(m => m.SdSinModule) },
  { path: 'sd-sin-line-rpt', loadChildren: () => import('./sd-sin-line-rpt/sd-sin-line-rpt.module').then(m => m.SdSinLineRptModule) },
  { path: 'sd-sin-vat-ext', loadChildren: () => import('./sd-sin-vat-ext/sd-sin-vat-ext.module').then(m => m.SdSinVatExtModule) },
  { path: 'mm-ppr', loadChildren: () => import('./mm-ppr/mm-ppr.module').then(m => m.MmPprModule) },
  { path: 'mm-por', loadChildren: () => import('./mm-por/mm-por.module').then(m => m.MmPorModule) },
  { path: 'mm-por-line-rpt', loadChildren: () => import('./mm-por-line-rpt/mm-por-line-rpt.module').then(m => m.MmPorLineRptModule) },
  { path: 'mm-gre', loadChildren: () => import('./mm-gre/mm-gre.module').then(m => m.MmGreModule) },
  { path: 'mm-gis', loadChildren: () => import('./mm-gis/mm-gis.module').then(m => m.MmGisModule) },
  { path: 'pp-mto', loadChildren: () => import('./pp-mto/pp-mto.module').then(m => m.PpMtoModule) },
  { path: 'pp-mor', loadChildren: () => import('./pp-mor/pp-mor.module').then(m => m.PpMorModule) },
  { path: 'ca-cre', loadChildren: () => import('./ca-cre/ca-cre.module').then(m => m.CaCreModule) },
  { path: 'mm-pin', loadChildren: () => import('./mm-pin/mm-pin.module').then(m => m.MmPinModule) },
  { path: 'ca-cpa', loadChildren: () => import('./ca-cpa/ca-cpa.module').then(m => m.CaCpaModule) },
  { path: 'zz-ape', loadChildren: () => import('./zz/ape/zz-ape.module').then(m => m.ZzApeModule) },
  { path: 'mm-ape', loadChildren: () => import('./mm-ape/mm-ape.module').then(m => m.MmApeModule) },
  { path: 'zz-table', loadChildren: () => import('./zz/table/zz-table.module').then(m => m.ZzTableModule) },
  { path: 'zz-image', loadChildren: () => import('./zz-img/zz-image.module').then(m => m.ZzImageModule) },
  { path: 'zz-pdf', loadChildren: () => import('./zz-pdf/zz-pdf.module').then(m => m.ZzPdfModule) },
  { path: 'ba-rec-rpt', loadChildren: () => import('./ba-rec-rpt/ba-rec-rpt.module').then(m => m.BaRecRptModule) },
  { path: 'ba-pay-rpt', loadChildren: () => import('./ba-pay/ba-pay-rpt.module').then(m => m.BaPayRptModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
